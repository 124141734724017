<template>
  <div class="login animated bounceInLeft">
    <back style="color:#000" v-if="backShow" />
    <van-icon
      name="arrow-left"
      style="margin-top:20px"
      size="25"
      @click="backUni"
      v-if="!backShow"
    />
    <h2 class="welcome">欢迎登录</h2>
    <div class="usermsg">
      <div class="msgList">
        <span class="iconfont icon-yonghu"></span>
        <van-field v-model="phone" placeholder="请输入手机号" clearable />
      </div>
      <div class="msgList">
        <span class="icon-mima iconfont"></span>
        <van-field v-model="pass" type="password" clearable placeholder="请输入密码" />
      </div>
      <p class="forget" @click="toForgetPage">忘记密码</p>
    </div>
    <div class="radio_btn">
      <van-radio-group v-model="memberType" direction="horizontal" @change="onChangeRadio">
        <van-radio name="0">普通会员</van-radio>
        <van-radio name="1">爱心企业</van-radio>
      </van-radio-group>
    </div>
    <div class="edit_btn">
      <van-button
        type="danger"
        round
        class="login_btn"
        size="small"
        @click="login"
        :disabled="isLogin"
      >登 录</van-button>
      <p v-show="rigisterShow" @click="$router.push(`/register`)">注册会员账号</p>
      <p v-show="!rigisterShow" @click="$router.push(`/apply`)">注册爱心企业账号</p>
    </div>
  </div>
</template>

<script>
import authApi from '@/api/auth';
import { Toast } from "vant";
export default {
  data() {
    return {
      phone: "",
      pass: "",
      isLogin: false,
      memberType: '0', // 登录会员的类型
      rigisterShow: true, // 根据会员登录类型显示哪个注册按钮 当前表示爱心企业
      loginStr: 'app/ap_member//auth/login', // 会员登录的接口部分
      userInfoStr: 'app/ap_member//member/query_info?memberId=&regionId=&id=', // 获取会员信息的接口部分
      loginForm: {},
      loginWishForm: {},
      backShow: true,//uniapp对应的返回按钮
      from: ''
    };
  },
  mounted() {

    if (this.$route.query.from) {//积分商城跳转相应页面from
      this.backShow = false
      this.from = this.$route.query.from
    }
    if (localStorage.userMsg) { // 注册成功的信息
      const userMsg = JSON.parse(localStorage.userMsg);
      if (userMsg) {
        this.phone = userMsg.username;
        this.pass = userMsg.password;
      }
    }
  },
  methods: {
    // 点击单选框更换登录接口
    onChangeRadio(name) {
      if (name === '1') {
        this.rigisterShow = false;
      }
      if (name === '0') {
        this.rigisterShow = true;
      }
    },
    backUni() {
      if (this.$route.query.shopId) {//页面返回按钮 有shopid表明跳转到商铺
        window.uni.navigateTo({ url: '/pages/home/jfscH5/jfscH5?from=' + this.from + '&shopId=' + this.$route.query.shopId })
      } else {
        window.uni.navigateTo({ url: '/pages/home/jfscH5/jfscH5?from=' + this.from })
      }
    },
    login() {
      if (!this.phone) {
        Toast("请输入手机号");
        return;
      }
      if (!this.pass) {
        Toast("请输入密码");
        return;
      }
      if (!this.memberType) {
        Toast("请选择会员类型");
        return;
      }
      this.isLogin = true;
      window.localStorage.userinfo = ''; // 清空缓存
      if (this.rigisterShow) { // 会员登入
        // 普通会员登录表单
        const loginCommonForm = {
          address: "",
          appName: this.appName,
          latitude: "",
          longitud: "",
          no: "",
          password: this.pass,
          phone: this.phone,
          sys: "",
          type: 0,
          version: "",
          cid: localStorage.cid || ''
        };
        console.log("登录数据", loginCommonForm)

        this.loginForm = loginCommonForm;
        this.loginWishForm = {};
        this.merberLoginData(); // 会员登入接口
      } else { // 爱心企业登入
        // 爱心企业登录表单
        const loginWishForm = {
          appName: this.appName,
          password: this.pass,
          phone: this.phone,
        };
        this.loginForm = {};
        this.loginWishForm = loginWishForm;
        this.wishLoginData(); // 爱心企业登入接口
      }
    },
    // 会员登入
    merberLoginData() {
      authApi.merberLogin(this.loginForm).then(res => {
        this.isLogin = false;
        if (res.code === 10000) {
          console.log(res.data)
          window.localStorage.userinfo = JSON.stringify(res.data);
          this.MemberInfoData();
        } else {
          Toast(res.message);
        }
      }).catch(err => {
        this.isLogin = false;
        Toast("无法登陆，请刷新后重试");
      });
    },
    // 爱心企业登入
    wishLoginData() {
      authApi.wishLogin(this.loginWishForm).then(res => {
        this.isLogin = false;
        console.log("企业登录", res)
        if (res.success) {
          window.localStorage.userinfo = JSON.stringify(res.data);
          window.localStorage.oldWishToken = JSON.stringify(res.data); // 爱心企业的用户信息
          this.getMemberInfo();
        } else {
          Toast(res.message);
        }
      }).catch(err => {
        this.isLogin = false;
        Toast("无法登陆，请刷新后重试");
      });
    },
    // 根据token值获取企业信息
    getMemberInfo() {
      authApi.wishInfomatin({ wishMemberId: '' }).then(res => {
        console.log("企业信息", res)
        if (res.success) { // 企业信息
          window.localStorage.userinfo = ''; // token值清空
          const data = JSON.stringify(res.data);
          window.localStorage.enterpriseInfo = data;
          window.localStorage.personalInformation = ''; // 会员信息清空
          this.$router.go(-1); //返回上一级
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 根据token值获取会员信息
    MemberInfoData() {
      authApi.memberInfomatin().then(res => {
        var merberIn = JSON.parse(window.localStorage.userinfo)
        if (res.success) {
          const data = JSON.stringify(res.data);
          merberIn.id = res.data.id;
          window.localStorage.userinfo = JSON.stringify(merberIn); // 会员的token
          window.localStorage.personalInformation = data;
          window.localStorage.enterpriseInfo = ''; // 企业信息数据清空
          if (this.appName == '爱旌德') {
            authApi.scoreAll({ appName: this.appName, memberId: res.data.id }).then((res) => {
              console.log('同步积分', res)
            })
          }
          if (!this.backShow) {
            if (this.$route.query.shopId) {//登录完成后跳转积分商城页面
              window.uni.navigateTo({ url: '/pages/home/jfscH5/jfscH5?userCivilization=' + window.localStorage.personalInformation + '&from=' + this.from + '&shopId=' + this.$route.query.shopId })
            } else {
              window.uni.navigateTo({ url: '/pages/home/jfscH5/jfscH5?userCivilization=' + window.localStorage.personalInformation + '&from=' + this.from })
            }
          } else {
            if (this.$route.query.shopId ) {
              const url = window.location.origin + '/#'
              location.replace(this.$route.query.url + '/pages/shopDetail/shopDetail?userCivilization=' + localStorage.personalInformation+ '&shopId=' +this.$route.query.shopId + '&url='+ encodeURIComponent(url) + '&env=browser' + '&isLogin=true' )
            } else if (this.$route.query.env ) {
              const url = window.location.origin + '/#'
              location.replace(this.$route.query.url + '/?userCivilization=' + localStorage.personalInformation + '&url='+ encodeURIComponent(url) + '&env=browser' + '&isLogin=true')
            } else {
              this.$router.go(-1); //返回上一级
                setInterval(() => {
                  window.location.reload(); // 刷新页面
              }, 100);
            }            
          }
          // this.$router.go(-1); //返回上一级


        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 根据身份进入忘记密码页
    toForgetPage() {
      // 普通会员
      if (this.rigisterShow) {
        this.$router.push('/find');
      } else {
        // 爱心企业
        this.$router.push({ path: '/find', query: { wishSign: 'wishSign' } })
      }
    }
  }
};
</script>

<style lang="less" scoped>
.login {
  padding: 0 20px;
  box-sizing: border-box;
  .back {
    font-size: 20px;
    position: absolute;
    left: 10px;
    top: 40px;
    z-index: 999;
  }
  .forget {
    text-align: right;
    color: #ccc;
    margin-top: 20px;
    font-size: 0.24rem;
  }
  .radio_btn {
    margin-top: 0.08rem;
    display: flex;
    justify-content: center;
    font-size: 0.24rem;
  }
  .edit_btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .login_btn,
    p {
      max-width: 130px;
    }
    .login_btn {
      margin: 40px auto 20px;
    }
    p {
      color: #e42417;
      font-size: 16px;
    }
  }
  .login_btn {
    padding: 0 30px;
    font-size: 16px;
  }
  .usermsg {
    margin-top: 65px;
    padding: 0 15px;
    box-sizing: border-box;
    .msgList {
      display: flex;
      justify-content: left;
      align-items: center;
      border-bottom: 1px solid #eee;
    }
  }
  .welcome {
    font-size: 20px;
    font-weight: 700;
    margin-top: 80px;
  }
}
</style>